import { useCyclesData } from '~/features/cycles';
import { useUserOrdersQuery } from '../queries';

export function useOrders() {
  const { uid } = useUserState();
  const { currentCycle } = useCyclesData();
  const {
    data: orders,
    isLoading,
    suspense,
    error,
  } = useUserOrdersQuery(uid.value);

  const currentCycleOrders = computed(() =>
    (orders.value || []).filter((order) => {
      return (
        currentCycle.value &&
        order.createdAtTimeMs >= currentCycle.value.startDate &&
        order.createdAtTimeMs <= currentCycle.value.endDate
      );
    })
  );

  return {
    orders,
    currentCycleOrders,
    isLoading,
    error,
    suspense,
  };
}
